import React, { FC, useMemo } from 'react';
import { graphql, PageProps } from 'gatsby';
import classnames from 'classnames';
import { MainLayout } from '../../templates/MainLayout';
import { t } from '../../i18n';
import styles from './styles.module.scss';
import { YellowHeader } from '../../components/main/YellowHeader';
import { IReviewsQuery } from '../../queries/reviews/types';
import { IHeaderQuery } from '../../queries/headers/types';
import { ReviewCard } from '../../components/reviews/ReviewCard';
import { ICaseQuery } from '../../queries/cases/types';
import DATA from '../../i18n/locales/en';
import { usePathPrefix } from '../../constants/hooks';

type IProps = PageProps<
  IReviewsQuery & IHeaderQuery & ICaseQuery,
  { locale: string }
>;

const ReviewsPage: FC<IProps> = ({
  location,
  pageContext: { locale = 'en' },
  data,
}) => {
  const prefix = usePathPrefix();

  const header = useMemo(
    () => data?.headers?.edges?.find((edge) => edge.node),
    [data]
  );

  const reviews = useMemo(
    () => data.reviews.edges.filter((edge) => edge.node),
    [data, locale]
  );

  const headerTitle = (
    <>
      {t('reviews.title')}
      <div className={styles.underscore} />
      <img
        src={`${prefix}/clutch-logo.svg`}
        alt="Clutch"
        className={styles.logo_clutch}
      />
    </>
  );

  return (
    <MainLayout
      locale={locale}
      title={DATA.reviews.seo.title}
      description={DATA.reviews.seo.description}
      keywords={DATA.reviews.seo.keywords}
    >
      <div className={styles.wrap}>
        <div className={classnames(styles.header)}>
          <YellowHeader header={headerTitle}>
            {header?.node?.html || ''}
          </YellowHeader>
        </div>
      </div>

      <div className={classnames('content', styles.grid)}>
        {reviews.map((edge) => (
          <ReviewCard
            locale={locale}
            node={edge.node}
            key={edge.node.fileAbsolutePath}
            caseUrl=""
          />
        ))}
      </div>
    </MainLayout>
  );
};

export const query = graphql`
  query ReviewsPageQuery {
    headers: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/reviews/header/header/" } }
    ) {
      ...HeaderFields
    }

    reviews: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/reviews/reviews/" } }
      sort: { fields: fileAbsolutePath, order: DESC }
    ) {
      ...ReviewFields
    }
  }
`;

export default ReviewsPage;
